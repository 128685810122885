import Image from 'global/Image';
import { assetPrefix } from 'constants/app';
import {
  PhoneWrapper,
  PhoneFrame,
  PhoneTop,
  PhoneScreen,
  PhoneButton,
  PhoneContent,
  PanelBox,
  Illustration,
} from './styled';
import { Panel } from '../uikit';

function Phone() {
  return (
    <>
      <PhoneWrapper>
        <PhoneFrame>
          <PhoneContent>
            <PhoneTop>
              <span className="sensor" />
              <span className="speaker" />
            </PhoneTop>
            <PhoneScreen
              style={{
                backgroundImage: `url(${assetPrefix}/images/alert2.webp)`,
              }}
            >
              <Image src="/images/alert2.webp" alt="screen" />
            </PhoneScreen>
            <PhoneButton />
          </PhoneContent>
          <Image src="/images/iphone6.webp" alt="phone" />
          <PanelBox>
            <Panel
              symbol="BBCA"
              description="Report: Penyampaian laporan tahunan"
              time="29 minutes ago"
              className="box-1"
            />
            <Panel
              symbol="BBCA"
              description="Report: Penyampaian laporan tahunan"
              time="29 minutes ago"
              className="box-2"
            />
          </PanelBox>
        </PhoneFrame>
        <Illustration src="/images/x9.svg" alt="Illustration" />
      </PhoneWrapper>
    </>
  );
}

export default Phone;
