import { useState } from 'react';
import clsx from 'clsx';
import ReactVisibilitySensor from 'react-visibility-sensor';
import Phone from './Phone';
import { SectionWrapper, Title, ContentWrapper, Description } from './styled';

function Notification() {
  const [viewed, setViewed] = useState(false);

  return (
    <ReactVisibilitySensor
      offset={{ top: 140, bottom: 140 }}
      partialVisibility
      onChange={(v) => setViewed((s) => s || v)}
    >
      <SectionWrapper>
        <ContentWrapper>
          <Title className={clsx('fadeInLeftX', viewed && 'fadeInLeft')}>
            Smart Notification
          </Title>
          <Description
            className={clsx('fadeInLeftX2', viewed && 'fadeInLeft2')}
          >
            Bagaikan mempunyai asisten yang memberitahu kamu sewaktu laporan dan
            berita terbaru dirilis
          </Description>
        </ContentWrapper>
        <Phone />
      </SectionWrapper>
    </ReactVisibilitySensor>
  );
}

export default Notification;
